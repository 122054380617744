import {JIRA_DOMAIN_KEY, JIRA_TOKEN_KEY, loadStorage, SELECTED_PROJECT_ID} from "@/helpers";

export function getProjects() {
    return jiraRequest("GET", "/rest/api/2/project", {})
}

export function getJiraDomain() {
    return loadStorage(JIRA_DOMAIN_KEY, '')
}

export function getJiraToken() {
    return loadStorage(JIRA_TOKEN_KEY, '')
}

export function getJiraProject() {
    return loadStorage(SELECTED_PROJECT_ID, '')
}


export function getJiraTasks(jql) {
    return jiraRequest('GET', '/rest/api/2/search', {
        jql: 'project=' + getJiraProject() + ' ' + (jql ? 'AND (' + jql + ') ' : ''),
        fields: 'key,id,summary,labels,issuetype',
        maxResults: 500000,
    })
}

export function getJiraBoards(projectKey = null) {
    if (!projectKey) {
        projectKey = getJiraProject()
    }

    return jiraRequest('GET', '/rest/agile/1.0/board', {
        projectKeyOrId: projectKey,
    })
}

export function getJiraSprint(boardId = null) {
    if (!boardId) {
        return []
    }

    return jiraRequest('GET', '/rest/agile/1.0/board/' + boardId + '/sprint', {
        state: 'active,future',
    })
}

export function getJiraUsers(query) {
    return jiraRequest('GET', '/rest/api/2/user/picker', {
        query: query,
    })
}

export function jiraCreateTask(task) {
    let data = {
        "fields": {
            "project": {
                "key": getJiraProject()
            },
            "summary": task.taskName,
            "issuetype": {
                "id": "10101" // задача
            },
            "priority": {
                "id": "3" // нормальный
            },
            "labels": task.tags,//.map(e => e === 'back' ? 'Backend' : e),
            "description": task.description,
            "timetracking": { // оценка
                "originalEstimate": (task.times.inHours + "").replace('.', ','),
                "remainingEstimate": (task.times.inHours + "").replace('.', ',')
            }
        }
    }

    if (task.sprint && task.sprint.id) {
        data.fields['customfield_10100'] = +task.sprint.id
    }

    //Если есть тег devops, ставим компонент DevOps. Исполнитель проставится автоматически
    if (task.tags && task.tags.length && task.tags.find(e => e === 'devops')) {
        data.fields['components'] = [{"name": "DevOps"}]
    } else {
        if (task.user && task.user.id) {
            data.fields['assignee'] = {"name": task.user.id}
        }
    }

    //components: 10367=DevOps,

    if (task.epic && task.epic.taskCode) {
        // ссылка на эпик
        data.fields['customfield_10101'] = task.epic.taskCode
    }

    return jiraRequest("POST", "/rest/api/2/issue/", data)
}

export function jiraCreateEpic(task) {
    let data = {
        "fields": {
            "project": {
                "id": getJiraProject()
            },
            "summary": task.taskName,
            "customfield_10103": task.taskName, // короткое название эпика
            "issuetype": {
                "id": "10000" // эпик
            },
            "priority": {
                "id": "3" // нормальный
            },
            "labels": task.tags,//.map(e => e === 'back' ? 'Backend' : e),
            "description": task.description,
        }
    }

    return jiraRequest("POST", "/rest/api/2/issue/", data)
}

export function jiraRequest(method, route, data) {

    if (!getJiraDomain()) {
        alert('insert jira domain')
    }
    if (!getJiraToken()) {
        alert('insert jira token')
    }

    //Используем прокси для обхода cors
    return fetch('/proxy.php', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                method: method,
                url: getJiraDomain() + route,
                data: data,
                headers: ['Content-Type: application/json', 'Authorization: Bearer ' + getJiraToken()]
            })
        }
    )
        .then(e => {
            return e.json()
        })
        .catch(e => {
            alert(e)
        })
}
