export const TEXTAREA_STORAGE_KEY = 'textarea_input'
export const PROCEDURES_STORAGE_KEY = 'procedures_input'
export const JIRA_DOMAIN_KEY = 'jira_domain_key'
export const JIRA_TOKEN_KEY = 'jira_token_key'
export const SELECTED_PROJECT_ID = 'selected_project_id'
export const SELECTED_TAB = 'selected_tab'
export const JIRA_SELECT_USER = 'jira_select_user'
export const JIRA_SELECT_BOARD = 'jira_select_board'
export const JIRA_SELECT_SPRINT = 'jira_select_sprint'


export function loadStorage(key, def) {
    const value = localStorage.getItem(key)
    return value !== null ? JSON.parse(value) : def
}

export function setStorage(key, val) {
    localStorage.setItem(key, JSON.stringify(val))
}

/**
 * Открыть диалоговое окно fancybox
 * @param element тег для вызова
 * @param afterClose действие при закрытии
 */
export function openFancybox(element, afterClose) {

    element.onkeyup = (e => {
        if (e.keyCode === 27) {
            window.$.fancybox.close();
        }
    });

    window.$.fancybox.open(element, {
        baseClass: 'fb-modal-vue fb-modal-html',
        afterClose: afterClose,

        trapFocus: true,
        // Remove buttons
        infobar: 0,
        toolbar: 0,

        //smallBtn: 0,

        // Disable keyboard navigation
        keyboard: 0,

        // Disable some modules
        slideShow: 0,
        fullScreen: 0,
        thumbs: 0,
        touch: 0,

        // Disable click event handlers
        clickContent: false,
        clickSlide: 'close',
        clickOutside: 'close',
        dblclickContent: false,
        dblclickSlide: false,
        dblclickOutside: false
    });
};

/**
 * Открыть картинку на просмотр
 * @param element
 * @param afterClose
 */
export function openFancyboxImage(element, afterClose) {

    element.onkeyup = (e => {
        if (e.keyCode === 27) {
            window.$.fancybox.close();
        }
    });

    window.$.fancybox.open(element, {
        afterClose: afterClose,
    });
}

export function fancyboxClose() {
    window.$.fancybox.close()
}