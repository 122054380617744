import {createApp} from 'vue'
import App from './App.vue'
import 'vue3-toastify/dist/index.css';
import 'fancybox/dist/css/jquery.fancybox.css';
import '@vueform/multiselect/themes/default.css';

import 'bootstrap/dist/js/bootstrap.bundle.js'

let $ = require('jquery');
require('fancybox')($);

window.$ = $

require('bootstrap/dist/css/bootstrap.css')

createApp(App).mount('#app')
