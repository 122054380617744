<template>

  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">Подготовка задач для Jira</a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">

            <li class="nav-item">
              <a class="nav-link active" @click.prevent="openFancybox($refs.authModal)" href="#">Авторизовать jira</a>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" type="button" id="dropdownMenuButton1"
                 data-bs-toggle="dropdown" aria-expanded="false">
                Прочие действия
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <div class="dropdown-item" style="cursor: pointer" @click="loadJiraEpics">
                    Загрузить эпики в редактор
                  </div>
                </li>
                <li>
                  <div class="dropdown-item" style="cursor: pointer" @click="loadJiraTaskToText">
                    Загрузить эпики c задачками в редактор
                  </div>
                </li>
                <li>
                  <div class="dropdown-item" style="cursor: pointer" @click="openFancybox($refs.procedureModal)">
                    Процедуры
                  </div>
                </li>
                <li>
                  <div class="dropdown-item" style="cursor: pointer" @click="openFancybox($refs.parsedTextModal)">
                    Text JSON
                  </div>
                </li>
                <li>
                  <div class="dropdown-item" style="cursor: pointer" @click="localStorageClear">
                    Сбросить локальное хранилище
                  </div>
                </li>
                <li>
                  <div class="dropdown-item" style="cursor: pointer" @click="loadDefaultProcedure">
                    Загрузить процедуры из кода
                  </div>
                </li>
              </ul>
            </li>


            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" type="button" id="dropdownMenuButton2"
                 data-bs-toggle="dropdown" aria-expanded="false">
                Полезные ссылки
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                <li>
                  <a class="nav-link" href="https://planningpokeronline.com/" target="_blank">Planning poker</a>
                </li>
                <li>
                  <a class="nav-link" href="https://easyretro.io/" target="_blank">Retro</a>
                </li>
              </ul>
            </li>


          </ul>

        </div>
      </div>
    </nav>
    <div class="p-3">
      <div class="row">

        <div class="col-6">

          <form class="mb-3">
            <div class="input-group input-group-sm">
              <span class="input-group-text">Проект</span>
              <select v-model="select_project" @change.prevent="loadJiraTasks" class="form-control form-control-sm">
                <option value="" disabled>Выберите проект</option>
                <option v-for="p in projects" :value="p.key">[{{ p.key }}] {{ p.name }} id{{ p.id }}</option>
              </select>
              <a class="input-group-text text-decoration-none" :href="jira_domain" target="_blank">🔗</a>
            </div>

            <div class="row">
              <div class="col-12 col-lg-6">
                <div class="input-group input-group-sm mt-2">
                  <span class="input-group-text">Доска</span>
                  <select v-model="selectBoard" class="form-control form-control-sm">
                    <option value="" disabled>Выберите доску</option>
                    <option v-for="p in loadBoards" :value="p.id">{{ p.name }} id{{ p.id }}</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="input-group input-group-sm mt-2">
                  <span class="input-group-text">Спринт</span>
                  <select v-model="selectSprint" class="form-control form-control-sm">
                    <option value="">Без спринта</option>
                    <option v-for="p in loadSprints" :value="p.id">[{{ p.state }}] {{ p.name }} id{{ p.id }}</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="input-group input-group-sm mt-2">
                  <span class="input-group-text">Исполнитель</span>
                  <multiselect
                      class="form-control form-control-sm"
                      v-model="selectUser"
                      :options="listUsers"
                      :searchable="true"
                      :allowEmpty="true"
                      :loading="listUserLoading"
                      @search-change="debounceUserFind"
                      placeholder="поиск"
                      noOptionsText="Ничего не нашлось"
                      label="name"
                      track-by="name"
                      value-prop="code"
                      :show-no-results="true"
                      :hide-selected="false"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :strict="false"
                      :native="false"
                  >
                  </multiselect>
                </div>
              </div>
            </div>


          </form>


          <div class="mb-3">
            <h3>Список задач {{ select_project }} #{{ selectTab + 1 }}</h3>
          </div>

          <ul class="nav nav-tabs">
            <li class="nav-item" v-for="tab in tabsList">
              <a class="nav-link" :class="{active: tab==selectTab}" href="#" @click.prevent="selectTab=tab">
                {{ tab + 1 }}
              </a>
            </li>
          </ul>

          <textarea v-model="textComputed" class="form-control mb-3" style="min-height: 10vh"
                    ref="mainTextarea"></textarea>

          <div class="alert alert-danger mt-3" v-if="isError">Ошибка парсинга {{ isError }}</div>
          <div class="alert alert-danger mt-3" v-if="isError2">Ошибка парсинга {{ isError2 }}</div>

          <div class="accordion mb-3" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true" aria-controls="collapseOne">
                  Доступные процедуры
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
                   data-bs-parent="#accordionExample">
                <div class="accordion-body">

                  <div v-for="proc in procedureParsec">
                    <div class="badge bg-secondary" @click.prevent="copyProc(proc)"
                         style="cursor:pointer;">
                      &lt;&gt; {{ proc.name }} {{ proc.vars }}
                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>


        </div>


        <div class="col-6">

          <div>
            <h3>Эпики / задачи</h3>

            <div class="input-group mb-3">
              <span class="input-group-text">Поиск</span>
              <input v-model="searchTask" class="form-control"/>
            </div>

            <template v-if="parsedText.epics.length">
              <template v-for="(task, i) in parsedText.epics" :key="i">
                <Epic :task="task"
                      :jira_domain="jira_domain"
                      :syncTask="syncTask('epic', task)"
                      @add-epic="addEpic(task)"/>

                <template
                    v-if="parsedText.tasks.filter(t=>t.epic && t.epic.taskName === task.taskName).filter(e=>!searchTask || e.taskName.indexOf(searchTask) !== -1).length">
                  <Task
                      v-for="(task, j) in parsedText.tasks.filter(t=>t.epic && t.epic.taskName === task.taskName).filter(e=>!searchTask || e.taskName.indexOf(searchTask) !== -1)"
                      :syncTask="syncTask('task', task)"
                      :jira_domain="jira_domain"
                      @add-task="addTask(task)"
                      :key="j"
                      :task="task"/>
                </template>
                <div v-else class="alert alert-secondary p-1">Список пуст</div>
              </template>

            </template>
            <div v-else class="alert alert-secondary">Список пуст</div>
          </div>

          <div>
            <h2>Задачи без эпика</h2>
            <template
                v-if="parsedText.tasks.filter(t=>!t.epic || !t.epic.taskName).filter(e=>!searchTask || e.taskName.indexOf(searchTask) !== -1).length">
              <Task
                  v-for="(task, i) in parsedText.tasks.filter(t=>!t.epic || !t.epic.taskName).filter(e=>!searchTask || e.taskName.indexOf(searchTask) !== -1)"
                  :syncTask="syncTask('task', task)"
                  @add-task="addTask(task)"
                  :jira_domain="jira_domain"
                  :key="i"
                  :task="task"/>
            </template>
            <div v-else class="alert alert-secondary">Список пуст</div>
          </div>

        </div>


        <div ref="authModal" style="display: none">

          <div class="form-group mb-3">
            <label>Jira Domain</label>
            <input type="text" class="form-control" v-model="jira_domain" placeholder="jira_domain">
          </div>
          <div class="form-group mb-3">
            <label>Jira Token</label>
            <input type="password" class="form-control" v-model="jira_token" placeholder="jira_token">
          </div>
          <div class="btn btn-primary" @click.prevent="testAuth">
            Проверить
          </div>


        </div>

        <div ref="procedureModal" style="display:none;">

          <div class="mb-3">
            <b>Процедуры</b>
          </div>
          <textarea v-model="procedureComputed" class="form-control"
                    style="min-height: 70vh; min-width: 80vw;"></textarea>

          <div class="alert alert-danger mt-3" v-if="isError2">Ошибка парсинга {{ isError2 }}</div>

          <div class="btn btn-secondary" @click.prevent="fancyboxClose">Закрыть</div>


        </div>

        <div ref="parsedTextModal" style="display:none;">

          <div class="mb-3">
            <b>Распознанный текст</b>
          </div>

          <template v-if="!isError && !isError2">
            <textarea :value="JSON.stringify(parsedText, null, 2)" class="form-control mt-2"
                      style="min-height: 70vh; min-width: 80vw;"></textarea>
          </template>

          <div class="alert alert-danger mt-3" v-if="isError">Ошибка парсинга {{ isError }}</div>
          <div class="alert alert-danger mt-3" v-if="isError2">Ошибка парсинга {{ isError2 }}</div>

          <div class="btn btn-secondary" @click.prevent="fancyboxClose">Закрыть</div>


        </div>

      </div>
    </div>
  </div>
</template>

<script>
import autosize from "autosize/dist/autosize.min";
import {
  fancyboxClose,
  JIRA_DOMAIN_KEY,
  JIRA_SELECT_BOARD,
  JIRA_SELECT_SPRINT,
  JIRA_SELECT_USER,
  JIRA_TOKEN_KEY,
  loadStorage,
  openFancybox,
  PROCEDURES_STORAGE_KEY,
  SELECTED_PROJECT_ID,
  SELECTED_TAB,
  setStorage,
  TEXTAREA_STORAGE_KEY
} from "@/helpers";
import {handleLine, PROCEDURES} from "@/parser";
import Task from "@/components/Task.vue";
import Epic from "@/components/Epic.vue";
import copy from 'copy-text-to-clipboard';
import {
  getJiraBoards,
  getJiraSprint,
  getJiraTasks,
  getJiraUsers,
  getProjects,
  jiraCreateEpic,
  jiraCreateTask
} from "@/jira";
import {toast} from "vue3-toastify";
import Multiselect from '@vueform/multiselect'

const debounce = require('lodash.debounce');

export default {
  name: 'App',
  data: () => ({
    text: loadStorage(TEXTAREA_STORAGE_KEY, `# [TEST-1] название эпика
[back][site] задачка для бека и сайта на 3 дня 3d
[front][admin] сделать пункт 1w 2d 3h 30m
[TEST-2][front][admin] уже имеющийся в жире пункт
<> сущность в админке {"entity": "Город", "dropdown": false}
[back] выше через <> указано название процедуры с параметрами

# не заведённый эпик
`),
    select_project: loadStorage(SELECTED_PROJECT_ID, ''),
    procedures: loadStorage(PROCEDURES_STORAGE_KEY, JSON.stringify(PROCEDURES, null, 2)),
    jira_domain: loadStorage(JIRA_DOMAIN_KEY, 'https://jira-test.artw.dev'),
    jira_token: loadStorage(JIRA_TOKEN_KEY, ''),
    selectTab: loadStorage(SELECTED_TAB, 0),
    isError: false,
    isError2: false,
    projects: [],
    loadTasks: [],
    loadBoards: [],
    selectBoard: "",
    loadSprints: [],
    selectSprint: loadStorage(JIRA_SELECT_SPRINT, ''),
    selectSprintName: "",
    selectUser: loadStorage(JIRA_SELECT_USER, ''),
    listUserLoading: false,
    listUsers: [],
    keyedLoadTasks: {},
    procedureParsec: [],
    searchTask: "",
    countTabs: 7,
  }),
  mounted() {
    if (this.jira_domain && this.jira_token) {
      this.loadProjects()
      this.loadJiraTasks()
      this.loadJiraBoards()
      if (this.selectUser) {
        this.userFind(this.selectUser)
      }
      this.handleProcedureParsec()
    }
    autosize(this.$refs.mainTextarea)

  },
  components: {Epic, Task, Multiselect},
  computed: {
    textComputed: {
      get() {
        return this.text;
      },
      set: debounce(function (newValue) {
        this.text = newValue;
      }, 200)
    },
    tabsList() {
      let array = [];
      for (let tab = 0; tab < this.countTabs; tab++) {
        array.push(tab)
      }
      return array;
    },
    procedureComputed: {
      get() {
        return this.procedures.replace(/\\n/g, "\\n\n");
      },
      set: debounce(function (newValue) {
        this.procedures = newValue.replace(/\\n(\r)?\n/g, "\\n");
        this.handleProcedureParsec()


      }, 200)
    },
    parsedText() {

      this.isError = false


      let context = {
        tasks: [],
        epics: [],
        user: {
          id: this.selectUser
        },
        sprint: {
          id: this.selectSprint,
          name: this.selectSprintName,
        },
        procedures: this.procedureParsec,
        lastEpic: null,
        lastTask: null,
      }

      try {
        this.text.split("\n").forEach((originalLine) => {
          handleLine(context, originalLine)
        })
      } catch (e) {
        this.isError = e
        console.log(e)
        context = {
          tasks: [],
          epics: [],
          user: {
            id: null
          },
          sprint: {
            id: null,
            name: null,
          },
          procedures: [],
          lastEpic: null,
          lastTask: null,
        }

      }

      return context

    },

    debounceUserFind() {
      return debounce(this.userFind, 100)
    }

  },
  methods: {

    userFind(query) {
      if (query) {
        this.listUserLoading = true
        getJiraUsers(query).then(result => {
          this.listUsers = result && result.users ? result.users.map(e => {
            return {
              name: e.displayName + ' (@' + e.name + ')',
              code: e.name,
            }
          }) : []

          this.listUserLoading = false
        })
      } else {
        this.listUsers = []
      }
    },

    handleProcedureParsec() {
      this.isError2 = false
      let proc = [];
      try {
        proc = JSON.parse(this.procedures)
      } catch (e) {
        this.isError2 = e
        console.log(e)
      }

      this.procedureParsec = proc
    },
    syncTask(type, task) {
      let tags = type !== 'epic' ? (task.tags ? task.tags : []).filter(e => ['back', 'front', 'devops'].indexOf(e) !== -1).join(',') : ''
      let key = type + ' ' + task.taskName + ' ' + tags
      return this.keyedLoadTasks[key]
    },
    openFancybox,
    fancyboxClose,
    addTask(task) {
      jiraCreateTask(task)
          .then(result => {
            if (result && result.key) {

              this.loadTasks.push({
                taskCode: result.key,
                taskName: task.taskName,
                type: 'task',
                tags: task.tags,
              })
              this.updateKeyedLoadTasks()
              console.log('add task ', result.key)
              toast.success("Задача создана", {autoClose: 500});
            }
          })
    },
    addEpic(task) {
      jiraCreateEpic(task)
          .then(result => {
            if (result && result.key) {
              console.log('add epic ', result.key)
              this.loadTasks.push({
                taskCode: result.key,
                taskName: task.taskName,
                type: 'epic',
                tags: task.tags,
              })
              this.updateKeyedLoadTasks()
              toast.success("Эпик создан", {autoClose: 500});
            }
          })
    },
    localStorageClear() {
      if (confirm('Удалить сохранённые в браузере данные?')) {
        localStorage.clear()
        this.handleProcedureParsec()
        toast.success("Кеш удалён", {autoClose: 500});
      }
    },
    loadDefaultProcedure() {
      if (confirm('Сбросить процедуры?')) {
        this.procedures = JSON.stringify(PROCEDURES, null, 2)
        toast.success("Процедуры сброшены", {autoClose: 500});
        this.handleProcedureParsec()
      }
    },
    copyProc(proc) {
      copy("<> " + proc.name + " " + (proc.vars ? proc.vars : ""))
      toast.success("Скопировано", {autoClose: 500});
    },
    testAuth() {
      getProjects().then(result => {
        if (result && result.length) {
          toast.success("Авторизация прошла корректно", {autoClose: 2000});
          this.loadProjects()
          this.loadJiraBoards()
          fancyboxClose()
        } else {
          alert('Ошибка авторизации')
        }
      })
    },
    loadProjects() {
      getProjects().then(result => {
        this.projects = result
        toast.success("Проекты загружены", {autoClose: 500});
      })
    },
    loadJiraBoards() {
      getJiraBoards().then(result => {
        this.loadBoards = result.values
        if (
            this.loadBoards && this.loadBoards.length
        ) {
          if (this.selectBoard === null || !this.loadBoards.find(e => e.id == this.selectBoard)) {
            this.selectBoard = this.loadBoards[0].id
          } else {
            this.selectBoard = ""
          }
        } else {
          this.selectBoard = ""
        }
        toast.success("Доски загружены", {autoClose: 500});
      })
    },
    loadJiraSprint(boardId) {
      getJiraSprint(boardId).then(result => {
        this.loadSprints = result.values.map(e => ({
          id: e.id,
          name: e.name,
          state: e.state,
        }))

        if (
            this.loadSprints && this.loadSprints.length
        ) {
          if (!this.selectSprint || !this.loadSprints.find(e => e.id == this.selectSprint)) {
            this.selectSprint = this.loadSprints[0].id
            this.selectSprintName = this.loadSprints[0].name + ""
          } else {
            this.selectSprintName = this.loadSprints.find(e => e.id == this.selectSprint).name
          }
        } else {
          this.selectSprint = ""
          this.selectSprintName = ""
        }
        toast.success("Доски загружены", {autoClose: 500});
      })
    },
    loadJiraEpics() {
      if (!confirm('Сбросить список задач?')) return

      getJiraTasks('issuetype=10000').then(result => {
        let text = '';
        if (result && result.issues) {
          result.issues.forEach(r => {
            text += '# [' + r.key + '] ' + r.fields.summary + "\n"
          })
        }
        toast.success("Список задач обновлён из эпиков", {autoClose: 500});

        this.setText(text)
      })
    },
    loadJiraTaskToText() {
      if (!confirm('Сбросить список задач?')) return

      let text = '# ЗАДАЧИ НЕ РАЗОБРАНЫ ПО ЭПИКАМ! \n';
      if (this.loadTasks && this.loadTasks.length) {
        this.loadTasks.forEach(r => {
          if (r.type === 'epic') {
            text += '# [' + r.taskCode + ']' + ' ' + r.taskName + "\n"
          } else {
            text += '[' + r.taskCode + ']' + ' ' + r.taskName + "\n"
          }

        })
      }

      this.setText(text)
      this.$refs.mainTextarea.dispatchEvent(new Event('change'))

    },
    loadJiraTasks() {
      getJiraTasks('').then(result => {
        this.loadTasks = []
        if (result && result.issues) {
          this.loadTasks = result.issues.map(e => ({
            taskCode: e.key,
            taskName: e.fields.summary,
            type: e.fields.issuetype.id === '10000' ? 'epic' : 'task',
            tags: e.fields.labels.map(l => l === 'Backend' ? 'back' : l),
          }))
          toast.success("Список задач загружен", {autoClose: 500});
        }

        this.updateKeyedLoadTasks()


      })
    },
    updateKeyedLoadTasks() {
      let keyed = {}
      this.loadTasks.forEach(e => {
        let tags = e.type !== 'epic' ? (e.tags ? e.tags : []).filter(e => ['back', 'front', 'devops'].indexOf(e) !== -1).join(',') : ''
        let key = e.type + ' ' + e.taskName + ' ' + tags
        keyed[key] = e
      })
      this.keyedLoadTasks = keyed
    },
    saveText(select_project, selectTab) {
      const key = TEXTAREA_STORAGE_KEY + '_' + select_project + '_' + selectTab;
      console.log('save text', key)
      setStorage(key, this.text)
    },
    loadText(select_project, selectTab) {
      const key = TEXTAREA_STORAGE_KEY + '_' + select_project + '_' + selectTab;
      console.log('load text', key)
      this.setText(loadStorage(key, ''))
    },
    setText(text) {
      this.text = text
      this.$nextTick(() => {
        this.$refs.mainTextarea.dispatchEvent(new Event('autosize:update'));
      })
    },
  },
  watch: {
    text() {
      setStorage(TEXTAREA_STORAGE_KEY, this.text)
    },
    procedures() {
      setStorage(PROCEDURES_STORAGE_KEY, this.procedures)
    },
    jira_domain() {
      setStorage(JIRA_DOMAIN_KEY, this.jira_domain)
    },
    jira_token() {
      setStorage(JIRA_TOKEN_KEY, this.jira_token)
    },
    selectUser() {
      setStorage(JIRA_SELECT_USER, this.selectUser)
    },
    select_project(newValue, oldValue) {
      setStorage(SELECTED_PROJECT_ID, newValue)
      this.saveText(oldValue, this.selectTab)
      this.loadText(newValue, this.selectTab)
      this.$nextTick(() => {
        this.loadJiraBoards()
      })
    },
    selectTab(newValue, oldValue) {
      setStorage(SELECTED_TAB, newValue)
      this.saveText(this.select_project, oldValue)
      this.loadText(this.select_project, newValue)
    },
    selectBoard(newValue) {
      setStorage(JIRA_SELECT_BOARD, newValue)
      if (this.selectBoard) {
        this.loadJiraSprint(this.selectBoard)
      } else {
        this.loadSprints = []
        this.selectSprint = null
      }
    },
    selectSprint(newValue) {
      setStorage(JIRA_SELECT_SPRINT, newValue)
      if (newValue) {
        this.selectSprintName = this.loadSprints.find(e => e.id == newValue)?.name
      } else {
        this.selectSprintName = ''
      }
    },
  }
}
</script>

<style>
.wrap {
  max-width: 1000px;
}

.main_input {
  width: 90%;
  min-height: 300px;
}

.epic_task {
  border: solid 1px;
  padding: 0.5rem 1rem;
  margin: 2rem 0;
}

.form-control .multiselect-wrapper {
  min-height: auto;
}

</style>