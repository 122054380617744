export const LINE_TYPE_EPIC = 'epic'
export const LINE_TYPE_TASK = 'task'
export const LINE_TYPE_PROCEDURE = 'procedure'
export const LINE_TYPE_DESCRIPTION = 'description'
export const LINE_TYPE_SKIP = 'skip'


/*
%entity% - подменить строкой
%hideIfEmpty_dropdown% - скрыть строку если dropdown=true
%hideIfNotEmpty_dropdown% - скрыть строку если dropdown=true
 */
export const PROCEDURES = [
    {
        name: 'сущность в админке',
        vars: '{"entity": "Город", "dropdown": 1}',
        text: `
[back][admin] круд сущности "%entity%" 4h
[back][admin] роут дропдауна сущности "%entity% %hideIfEmpty_dropdown% 1h
[front][admin] круд сущности "%entity%" 4h
`,
    },
    {
        name: 'инфоблок на сайте',
        vars: '{"entity": "Баннер со ссылкой"}',
        text: `
[front][site] вёрстка инфоблока "%entity%" 2h
[front][site] создать структуру инфоблока "%entity%" 1h
[front][site] подключить инфоблок к структуре "%entity%" 2h
`,
    },
    {
        name: 'инфоблок с сущностью на сайте',
        vars: '{"entity": "Блок новостей"}',
        text: `
[back][site] роут на получение данных для инфоблока "%entity%" 1h
[front][admin] создать структуру инфоблока "%entity%" 1h
[front][site] вёрстка инфоблока "%entity%" 6h
[front][site] подключить инфоблок к структуре "%entity%" 6h
`,
    },
    {
        name: 'вынести сущность на сайт списком',
        vars: '{"entity": "Новости", "filter": 0, "paginate": 0}',
        text: `
[back][site] роут на список сущности "%entity%" 2h
[back][site] настройка фильтра "%entity% %hideIfEmpty_filter% 3h
[front][site] вёрстка страницы "%entity%" 1d
[front][site] вёрстка фильтра "%entity% %hideIfEmpty_filter% 2d
[front][site] вёрстка пагинации "%entity% %hideIfEmpty_paginate% 2h
[front][admin] создание страницы в админке, наполнение инфоблоком "%entity%" 4h
[front][site] интеграция вёрстки к беку "%entity%" 2h
[front][site] подключение фильтра "%entity% %hideIfEmpty_filter% 3d
[front][site] подключение пагинации "%entity% %hideIfEmpty_paginate% 4h
`,
    },
    {
        name: 'вынести сущность на сайт с детальной страницей',
        vars: '{"entity": "Новости"}',
        text: `
[back][site] роут на детальную страницу сущности "%entity%" 2h
[front][site] создать роут на детальную страницу "%entity%" 1h
[front][site] вёрстка детальной страницы "%entity%" 6h
[front][site] интеграция вёрстки к беку "%entity%" 4h
`,
    }
]

export function handleLine(context, originalLine) {

    let lineType = getLineType(originalLine)

    switch (lineType) {
        case LINE_TYPE_EPIC:
            handleEpic(context, originalLine)
            break;
        case LINE_TYPE_DESCRIPTION:
            handleDescription(context, originalLine)
            break;
        case LINE_TYPE_TASK:
            handleTask(context, originalLine)
            break;
        case LINE_TYPE_PROCEDURE:
            handleProcedure(context, originalLine)
            break;
        case LINE_TYPE_SKIP:
            //do nothing
            handleDescription(context, originalLine)
            break;

        default:

    }

}

export function handleEpic(context, originalLine) {

    let epic = parseEpic(originalLine)

    if (!context.epics) {
        context.epics = []
    }

    context.epics.push(epic)
    context.lastEpic = epic
    context.lastTask = epic
}

export function parseEpic(originalLine) {
    let line = originalLine.trim()

    line = line.replace('#', '').trim()

    let epic = parseTask(line)

    return epic
}

export function getLineType(originalLine) {
    let line = originalLine.trim()

    if (line === '') {
        return LINE_TYPE_SKIP
    }

    if (line[0] === '#') {
        return LINE_TYPE_EPIC
    }

    if (line[0] === '[') {
        return LINE_TYPE_TASK
    }

    if (line[0] === '<' && line[1] === '>') {
        return LINE_TYPE_PROCEDURE
    }

    return LINE_TYPE_DESCRIPTION

}


export function handleDescription(context, originalLine) {

    if (context.lastTask) {
        context.lastTask.description +=
            (context.lastTask.description ? "\n" : '')
            + originalLine
    }
}

export function handleProcedure(context, originalLine) {

    let procedure = parseProcedure(context, originalLine)

    if (procedure.text) {

        procedure.text.split("\n").forEach((line) => {

            let newLine = line;
            if (procedure.vars) {
                Object.keys(procedure.vars).forEach((k) => {
                    const v = procedure.vars[k]

                    //обработка строки
                    newLine = newLine.replace('%' + k + '%', v)

                    //обработка скрытия строки если true
                    if (newLine.indexOf('%hideIfEmpty_' + k + '%') !== -1) {
                        if (v) {
                            newLine = newLine.replace('%hideIfEmpty_' + k + '%', '').trim()
                        } else {
                            newLine = ''
                        }
                    }

                    //обработка скрытия строки если false
                    if (newLine.indexOf('%hideIfNotEmpty_' + k + '%') !== -1) {
                        if (!v) {
                            newLine = newLine.replace('%hideIfNotEmpty_' + k + '%', '').trim()
                        } else {
                            newLine = ''
                        }
                    }
                })
            }

            handleLine(context, newLine)
        })
    }

}

export function parseProcedure(context, originalLine) {
    let line = originalLine.replace('<>', '').trim()

    let vars = line.replace(/[^\{]+/, '').trim()

    let name = line.replace(vars, '').trim()

    let procedure = context.procedures.find(e => e.name === name)

    if (!procedure) {
        throw new Error('Нет процедуры ' + name);
    }

    return {
        name,
        vars: vars ? JSON.parse(vars) : [],
        text: procedure ? procedure.text : ''
    }
}

export function handleTask(context, originalLine) {
    let line = originalLine.trim()
    let task = parseTask(line)

    if (context.user) {
        task.user = context.user
    }
    if (context.sprint) {
        task.sprint = context.sprint
    }

    if (context.lastEpic) {
        task.epic = {
            taskName: context.lastEpic.taskName,
            taskCode: context.lastEpic.taskCode
        }
    }

    context.tasks.push(task)
    context.lastTask = task

}

export function parseTask(originalLine) {

    let line = originalLine.trim()

    let taskName = line

    let taskCode = taskName.match(/\[[A-Z]+\-\d+\]/g)
    taskCode = taskCode && taskCode.length >= 1 ? taskCode[0].replace('[', '').replace(']', '') : null

    if (taskCode) {
        taskName = taskName.replace('[' + taskCode + ']', '').trim()
    }

    let findTags = line.match(/\[[A-Za-z]+\]/g)
    const tags = (findTags ? findTags : []).map(e => {
        return e.replace('[', '').replace(']', '')
    })

    let times = parseTime(line)
    if (times.replaceInName) {
        taskName = taskName.replace(times.replaceInName, '')
    }

    return {
        taskName,
        taskCode,
        times,
        tags,
        epic: null,
        description: '',
    }
}

export function parseTime(line) {
    const time = line.match(/(\s\d+w)?(\s\d+d)?(\s\d+h)?\s?(\s\d+m)?(\s+)?$/)
    let times = {
        replaceInName: '',
        w: 0,
        d: 0,
        h: 0,
        m: 0,
        inHours: 0
    }
    if (time && time[0] && time[0].trim() !== "") {
        times.replaceInName = time[0]

        if (time[1]) {
            let tmp = time[1]
            tmp = tmp.replace('w', '').trim()
            times.w = parseInt(tmp)
            times.inHours += times.w * 5 * 8
        }

        if (time[2]) {
            let tmp = time[2]
            tmp = tmp.replace('d', '').trim()
            times.d = parseInt(tmp)
            times.inHours += times.d * 8
        }

        if (time[3]) {
            let tmp = time[3]
            tmp = tmp.replace('h', '').trim()
            times.h = parseInt(tmp)
            times.inHours += times.h
        }

        if (time[4]) {
            let tmp = time[4]
            tmp = tmp.replace('m', '').trim()
            times.m = parseInt(tmp)
            times.inHours += +(times.m / 60).toFixed(2)
        }

    }

    return times
}

