<template>
  <div class="card mb-2">
    <div class="card-body p-2">
      <div class="row">
        <div class="col-8">
          {{ task.taskName }}
        </div>
        <div class="col-4 text-lg-end">
          <span :class="{'text-danger': !task.times.inHours}">
            {{ task.times.inHours }}h
          </span>
          <div class="btn btn-sm btn-success" @click="$emit('add-task')" v-if="!syncTask">
            +
          </div>
          <a v-else :href="jira_domain+'/browse/'+syncTask.taskCode" target="_blank" class="btn btn-sm btn-secondary">
            {{ syncTask.taskCode }}
          </a>
        </div>
        <div class="col-12">
          <div v-if="!syncTask">
            <div class="badge bg-primary ms-1" v-if="task.user && task.user.id" title="Пользователь">
              @{{ task.user.id }}
            </div>
            <div class="badge bg-primary ms-1" v-if="task.sprint && task.sprint.id" title="Спринт">
              {{ task.sprint.name ? task.sprint.name : 'Sprint ' + task.sprint.id }}
            </div>
          </div>
          <div>
            <div class="badge bg-success ms-1" v-if="task.epic && task.epic.taskCode" title="Код эпика">
              {{ task.epic.taskCode }}
            </div>
            <div class="badge bg-primary ms-1" v-if="task.taskCode" title="Код задачи">
              {{ task.taskCode }}
            </div>
            <div class="badge bg-secondary ms-1" v-for="tag in task.tags" title="тег">
              {{ tag }}
            </div>
          </div>
          <pre v-if="task.description" class="text-secondary small ms-1 mb-0">{{ task.description }}</pre>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: Object,
    syncTask: Object,
    jira_domain: String | null,
  }
}
</script>
