<template>
  <div class="card mb-2">
    <div class="card-body bg-info-subtle p-2">
      <div class="row">
        <div class="col-8">
          <b v-if="task.taskCode">[{{ task.taskCode }}]</b>
          {{ task.taskName }}
        </div>
        <div class="col-4 text-end">
          <div class="btn btn-sm btn-success" @click="$emit('add-epic')" v-if="!syncTask">
            +
          </div>
          <a v-else :href="jira_domain+'/browse/'+syncTask.taskCode" target="_blank" class="btn btn-sm btn-secondary">
            {{ syncTask.taskCode }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: Object,
    syncTask: Object,
    jira_domain: String | null,
  }
}
</script>
